<template>
  <div class="admin-create-location">
    <loading-screen :is-loading="isSaving"></loading-screen>
    <div class="page-header">
      <h1 class="page-title">Create Location</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>

    <!-- Form -->
    <div class="form">
      <a-row :gutter="30">
        <a-col :span="12">
          <a-form-item label="Location Name">
            <a-input size="large" v-model="location.displayName"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Location Code">
            <a-input size="large" v-model="location.roomCode"></a-input>
          </a-form-item>
        </a-col>
      </a-row>

      <a-form-item label="Visibility">
        <a-radio-group v-model="location.scope">
          <a-radio :value="1">Shown to organisation</a-radio>
          <!-- <a-radio :value="2">Shown to whole tenant</a-radio> -->
          <a-radio :value="3">Hidden from lists</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-row :gutter="30">
        <a-col :span="12">
          <a-form-item>
            <template #label>
              <a target="_blank" href="https://what3words.com/daring.lion.race"
                >What3Words</a
              >
            </template>
            <a-input size="large" v-model="location.what3Words"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Radius">
            <a-input size="large" v-model="location.accuracy"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
    </div>
    <!-- / Form -->

    <!-- Advanced Settings -->
    <div
      v-if="!isLoading && location"
      @click.prevent="showAdvancedSettings = !showAdvancedSettings"
      class="advanced-settings-toggle"
    >
      {{ showAdvancedSettings ? "Hide" : "Show" }} advanced settings
      <a-icon
        v-if="!showAdvancedSettings"
        type="down"
        style="margin-left: 10px"
      ></a-icon>
      <a-icon
        v-if="showAdvancedSettings"
        type="up"
        style="margin-left: 10px"
      ></a-icon>
    </div>

    <div v-if="showAdvancedSettings" class="form">
      <a-row :gutter="20">
        <a-col :span="8">
          <a-form-item label="Elevation (m)">
            <a-input size="large" v-model="location.elevation"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="Latitude">
            <a-input size="large" v-model="location.latY"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="Longitude">
            <a-input size="large" v-model="location.longX"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
    </div>
    <!-- / Advanced Settings -->

    <a-button
      @click.prevent="save"
      class="btn-rounded save-button"
      size="large"
      type="primary"
      >Save</a-button
    >
  </div>
</template>

<script>
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import locations from "../../../../api/locations";
import { mapGetters } from "vuex";
export default {
  components: { LoadingScreen },
  data() {
    return {
      isSaving: false,
      location: {
        displayName: "",
        roomCode: "",
        scope: 1,
        what3Words: "",
        accuracy: "",
        elevation: "",
        latY: "",
        longX: "",
      },
      showAdvancedSettings: false,
    };
  },
  methods: {
    goBack() {
      this.$router.push("/admin/settings/locations");
    },

    getValidationErrors() {
      let errors = [];
      if (this.location.displayName.trim().length == 0) {
        errors.push("Please provide a location name");
      }
      if (this.location.roomCode.trim().length == 0) {
        errors.push("Please provide a location code");
      }
      return errors;
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      let params = {
        ...this.location,
        ownerId: this.selectedOrganisation.id,
      };
       if (params.accuracy.trim().length == 0) {
        delete params["accuracy"];
      }
      if (params.elevation.trim().length == 0) {
        delete params["elevation"];
      }
      if (params.latY.trim().length == 0) {
        delete params["latY"];
      }
      if (params.longX.trim().length == 0) {
        delete params["longX"];
      }

      vm.isSaving = true;
      locations
        .addLocation(this.tenantId, params)
        .then(() => {
          vm.isSaving = false;
          vm.$message.success("Location created successfully");
          vm.$router.push("/admin/settings/locations");
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error creating location");
        });
    },
  },
  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
  },
  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
    }),
  },
};
</script>

<style lang="scss">
.admin-create-location {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }

  .advanced-settings-toggle {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;

    background: rgba(81, 85, 234, 0.2);
    cursor: pointer;
    border-radius: 6px;
    // color: #fff;
    padding: 9px;
    font-weight: 500;
  }
}
</style>